import { IApplicationState } from '@bees-web/core/types'
import processStateExpression from '../joker/processStateExpression'

const processUrlStateExpression = (
  url: string,
  state: IApplicationState = {}
): string =>
  url.replace(/<\^[^^]+\^>/g, (match) => processStateExpression(match, state))

export default processUrlStateExpression
