import { getInteractiveGlobal } from '@bees-web/nfa-interactive-global'
import { IApplicationState } from '@bees-web/core/types'
import { Dispatch } from 'react'

interface InteractiveGlobalMapArgs {
  dispatch: Dispatch<any>
  state: IApplicationState
  globalNamespace?: string
}

export interface IInteractiveMap {
  [interactiveId: string]: Record<string, unknown>
}

type InteractiveGlobalMap = (args: InteractiveGlobalMapArgs) => IInteractiveMap

export const interactiveGlobalMap: InteractiveGlobalMap = ({
  dispatch,
  state,
}) => getInteractiveGlobal(dispatch, state.globals)
