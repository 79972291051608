/* eslint-disable security/detect-object-injection */
import { GetServerSidePropsContext } from 'next'
import { BlocksServerData, QueryParams } from '../../interfaces'
import { IPage, IBlock, IApplicationState } from '@bees-web/core/types'
import { blockUrlBuilder } from '../../hooks/useBlockSchema'
import fetcher from '../../../core/fetcher'
import getDeferredUrl from './getDeferredUrl'
import { simplify } from '../../../core/tools/data'

async function fetchBlock(
  url: string,
  context: GetServerSidePropsContext
): Promise<IBlock | null> {
  try {
    const response = await fetcher<IBlock>(context)(url)
    return simplify(response)
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error.message)
    return null
  }
}

export async function deepBlockFetcher(
  block: IBlock,
  context: GetServerSidePropsContext,
  query: QueryParams,
  state: IApplicationState
): Promise<BlocksServerData> {
  const cache: BlocksServerData = {}
  const deferredUrl = getDeferredUrl(block, true)

  const requests: Promise<BlocksServerData>[] = []
  const processBlocks = (blocks: IBlock[] = []): void => {
    if (blocks.length) {
      blocks.forEach((block) =>
        requests.push(deepBlockFetcher(block, context, query, state))
      )
    }
  }

  if (deferredUrl) {
    const blockUrl = blockUrlBuilder(deferredUrl.url, query, state)
    const response = await fetchBlock(blockUrl, context)
    if (response) {
      cache[blockUrl] = response
      processBlocks(response.blocks)
    }
  } else {
    processBlocks(block.blocks)
  }

  if (requests.length) {
    const responses = await Promise.all(requests)
    for (const response of responses) {
      Object.assign(cache, response)
    }
  }

  return cache
}

export default async function getBlocksServerData(
  page: IPage,
  context: GetServerSidePropsContext,
  query: QueryParams,
  state: IApplicationState
): Promise<BlocksServerData> {
  const cache: BlocksServerData = {}
  const { blocks = [] } = page

  if (blocks.length) {
    const requests: Promise<BlocksServerData>[] = []

    blocks.forEach((block) =>
      requests.push(deepBlockFetcher(block, context, query, state))
    )

    const responses = await Promise.all(requests)
    for (const response of responses) {
      Object.assign(cache, response)
    }
  }

  return cache
}
