/* eslint-disable brace-style */
/* eslint-disable security/detect-object-injection */
import { IPageConfig } from '@bees-web/core/types'
import { match, MatchFunction } from 'path-to-regexp'
import { dependencies } from '../../config' // Take this from modules

export type TRouteConfig = IPageConfig & {
  namespace: string
}

export type TDynamicRouteConfig = TRouteConfig & {
  process: MatchFunction<Record<string, unknown>>
}

export type StaticRoutes = { [key: string]: TRouteConfig }
export type DynamicRoutes = {
  _root?: {
    [key: string]: TDynamicRouteConfig
  }
  [key: string]: { [key: string]: TDynamicRouteConfig }
}

export interface PagesRouter {
  dynamics: DynamicRoutes
  statics: StaticRoutes
}

const getMappedRoutes = (): PagesRouter => {
  console.info('Processing routes map')
  let statics: StaticRoutes = {}
  let dynamics: DynamicRoutes = {}
  Object.keys(dependencies).forEach((namespace) => {
    const pages = dependencies[namespace].pages

    Object.keys(pages).forEach((path) => {
      const pageConfig = pages[path]
      const page =
        typeof pageConfig === 'string'
          ? { source: pageConfig, namespace }
          : { ...pageConfig, namespace }

      if (path.indexOf(':') >= 0) {
        const key = path.split('/')[1]
        const isRoot = key.indexOf(':') >= 0
        const content: {
          [key: string]: TDynamicRouteConfig
        } = {
          [path]: {
            ...page,
            process: match(path, { decode: decodeURIComponent }),
          },
        }
        dynamics = isRoot
          ? {
              ...dynamics,
              _root: {
                ...dynamics._root,
                ...content,
              },
            }
          : {
              ...dynamics,
              [key]: {
                ...dynamics[key],
                ...content,
              },
            }
      } else {
        statics = {
          ...statics,
          [path]: page,
        }
      }
    })
  })
  return {
    statics,
    dynamics,
  }
}

export default getMappedRoutes
