import React, { FC, ReactNode } from 'react'
import Head from 'next/head'
import { IPage } from '@bees-web/core/types'
import Script, { ScriptProps } from 'next/script'

export interface MetaPageProps {
  template?: IPage
  scripts?: ScriptProps[]
  children?: ReactNode
}

const MetaPage: FC<MetaPageProps> = ({
  template = null,
  scripts,
  children = null,
}: MetaPageProps) => {
  if (template) {
    const { meta = {} } = template
    const title = meta['title'] || ''
    const icon = meta['icon'] || ''
    return (
      <div className="page-container">
        {scripts && scripts.map((script) => <Script {...script} />)}
        {title && (
          <Head>
            <title>{title}</title>
            <meta property="og:title" content={title} key="title" />
            {icon && (
              <>
                <link rel="icon" type="image/x-icon" href={icon} />
                <link rel="shortcut icon" type="image/x-icon" href={icon} />
              </>
            )}
          </Head>
        )}
        {children}
      </div>
    )
  }

  return null
}

export default MetaPage
