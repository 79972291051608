import { IApplicationState } from '@bees-web/core/types'
import { replaceVariablesInString } from '../../../utils/fill-loco-lib'
import normalizeStateExpression from './normalizeStateExpression'

export default function processStateExpression(
  expression: string,
  state: IApplicationState
): string {
  if (expression && state) {
    const simplifiedExp = normalizeStateExpression(expression)
    const processed = replaceVariablesInString(simplifiedExp, state, {})

    return typeof processed === 'undefined' || processed === 'undefined'
      ? ''
      : processed
  }

  return expression || null
}
