/* eslint-disable security/detect-object-injection */
import React, { FC, createElement, useMemo, useCallback } from 'react'
import { useStore } from 'react-redux'
import { IBlock } from '@bees-web/core/types'
import DeferredBlock from './DeferredBlock'
import { getComponent } from '../../utils/VL'
import { mergeProperties } from '../../utils/mergeProperties'
import { CUSTOM_BLOCK_TAG } from '../../utils/VL/getComponent'
import getSiteMap from '../routing/getSiteMap'
import { useInteractive } from '../hooks'
import ErrorBoundary from './ErrorBoundary'

export interface BlockProps {
  template: IBlock
}

const sitemap = getSiteMap()

const setBlockMeta = (block: IBlock): void => {
  if (!block.meta) block.meta = {}
  if (block.attributes?.href) {
    const { href } = block.attributes
    const dynamicVariation = `${href.substring(0, href.lastIndexOf('/'))}/[id]`

    block.meta.externalLink =
      href === '/' ||
      (!sitemap.routes[href] && !sitemap.routes[dynamicVariation])

    if (sitemap?.dynamicRoutes[dynamicVariation]) {
      block.meta.dynamicRoute = dynamicVariation
    }
  }
}

const Block: FC<BlockProps> = React.memo(({ template }: BlockProps) => {
  const store = useStore()
  const Component = useMemo(() => getComponent(template) as any, [template])
  const {
    interactiveId,
    blockType,
    blocks = [],
    deferred,
    ...templateProps
  } = template
  if (!Component) return null
  if (deferred) return <DeferredBlock template={template} />

  setBlockMeta(template)

  const interactiveProps = useInteractive(interactiveId)

  const props = useMemo(
    () => ({
      ...mergeProperties(templateProps, interactiveProps), // Interactive more than blockProps
      key: templateProps.name,
      store: blockType === CUSTOM_BLOCK_TAG ? store : {},
    }),
    [template, interactiveProps]
  )

  const renderBlocks = useCallback(
    (blocks) =>
      blocks.map((block, idx) => (
        <ErrorBoundary
          blockName={block.name || ''}
          key={block.name || `b-${idx}`}
        >
          <Block key={block.name} template={block} />
        </ErrorBoundary>
      )),
    []
  )

  try {
    return createElement(
      Component,
      { ...props, ...props.attributes }, // TODO: Replace it for props
      renderBlocks(blocks)
    )
  } catch {
    return <>{`Error: ${template.blockType}`}</>
  }
})

export default Block
